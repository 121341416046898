





























// @ is an alias to /src

import { defineComponent } from '@vue/composition-api';
import useContext, { IUseContext } from '@/composition/context';
import router from '@/router';
export default defineComponent({
  setup() {
    const { translations } = useContext() as unknown as IUseContext;
    const event = parseInt(router.currentRoute.params.event, 10);
    return { translations, event};
  },
});

